// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-diversity-inclusion-js": () => import("../src/pages/diversity-inclusion.js" /* webpackChunkName: "component---src-pages-diversity-inclusion-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sessions-js": () => import("../src/pages/sessions.js" /* webpackChunkName: "component---src-pages-sessions-js" */),
  "component---src-pages-venue-js": () => import("../src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */),
  "component---src-pages-volunteer-js": () => import("../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */)
}

